import { Box, Card, Flex, SimpleGrid, Text } from '@chakra-ui/react';
import {
  attendanceReservation,
  finishReservation,
  getReservation,
  removeUserFromReservation
} from 'api/services/reservations';

import DataTable from 'components/shared/data-table';
import { Column } from 'components/shared/data-table/types';
import ControlledButton from 'components/with-controlled/button';
import { USER_ROLES } from 'constants/users';
import { useEffect, useMemo, useState } from 'react';
import { AttendanceReservationRequest, GetReservationResponse } from 'api/services/reservations/types';
import { useNavigate, useParams } from 'react-router-dom';
import { useCustomSnackbar } from 'utils/custom-snackbar';
import { MadeReservationsRowObj } from './types';
import CancelReservationModal from './cancel-reservation-modal';
import { useUI } from 'contexts/UIContext';
import { GetStudentResponse } from 'api/services/students/types';
import { deleteStudent, getStudent } from 'api/services/students';
import { getClasses } from 'api/services/classes';
import { PAGINATION_PARAMS_FOR_DROPDOWN } from 'constants/fetch';
import { ClassesResponse } from 'api/services/classes/types';
import CreateOrEditStudentModal from 'components/students/create-or-edit-student-modal';
import { convertUTCToDisplayPdf, convertUTCToDisplayPdfJustDay } from 'helpers/date';
import GenerateParticipantsFile from 'components/reservations/generate-participants-file';
import { pdf } from '@react-pdf/renderer';
import { useAppSelector } from 'lib/hooks';
import saveAs from 'file-saver';

const columnsDataCheck: Column<MadeReservationsRowObj>[] = [
  { name: 'studentName', displayName: 'Öğrenci Adı' },
  { name: 'lessonSubjectsFormatted', displayName: 'Kazanımlar/Soru Sayısı' }
];

export default function ReservationDetail() {
  const { id } = useParams();
  const { showModal } = useUI();
  const snackbar = useCustomSnackbar();
  const navigate = useNavigate();
  const organizationLogo = useAppSelector((selector) => selector.session?.organization?.logoUrl);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [reservation, setReservation] = useState<GetReservationResponse>();
  const [participants, setParticipants] = useState<string[]>([]);

  const fetchReservation = async (page?: number, pageSize?: number, getOwnReservations?: boolean) => {
    try {
      const reservationResponse: GetReservationResponse = await getReservation(id.toString());

      if ((reservationResponse as any).status === 404) {
        navigate('/panel/reservations');
      }

      const formattedReservations = reservationResponse.madeReservations.map((reservation) => {
        return {
          ...reservation,
          lessonSubjectsFormatted: reservation.lessonSubjects
            .map((subject) => `${subject.lessonSubject} - ${subject.questionCount}`)
            .join(', '),
          id: reservation.studentId,
          className: reservation.className
        };
      });

      if (Array.isArray(reservationResponse.madeReservations) && reservationResponse.madeReservations.length > 0) {
        const participantsList: string[] = [];

        reservationResponse.madeReservations.map((madeReservation) => {
          if (madeReservation.participationStatus === 0) {
            participantsList.push(madeReservation.studentId.toString());
          }
        });

        setParticipants(participantsList);
      }

      setReservation({
        ...reservationResponse,
        madeReservations: formattedReservations
      });
      setLoading(false);
    } catch (error: any) {
      setError(error.data);
      console.log(error);
    }
  };

  const handleOnSuccessCallback = async () => {
    await fetchReservation();
  };

  useEffect(() => {
    if (id) {
      fetchReservation();
    }
  }, []);

  const handleOnChangeParticipants = (id: string, status: string) => {
    setParticipants((prev) => {
      if (status === '2') {
        return [...prev, id];
      } else if (status === '1') {
        return prev.filter((item) => item !== id);
      }
      return prev;
    });
  };

  const handleClickSaveAttendance = async () => {
    try {
      const attendanceList = reservation.madeReservations.map((madeReservation) => {
        return {
          studentId: madeReservation.studentId,
          isPresent: participants.includes(madeReservation.studentId.toString()),
          reason: ''
        };
      });

      const payload: AttendanceReservationRequest = {
        attendanceList
      };

      await attendanceReservation(id, payload);

      snackbar('Yoklama başarıyla kaydedildi', 'success');
    } catch (error) {}
  };

  const getActiveStatus = useMemo(() => {
    if (reservation?.status === 'Active') {
      return 'Etkin';
    }
    if (reservation?.status === 'Passed') {
      return 'Geçmiş';
    }
    if (reservation?.status === 'Canceled') {
      return 'İptal';
    }
  }, [reservation?.status]);

  const getActiveStatusColors = useMemo(() => {
    if (reservation?.status === 'Active') {
      return '#a5cc76';
    }
    if (reservation?.status === 'Passed') {
      return '#9E9E9E';
    }
    if (reservation?.status === 'Canceled') {
      return '#e57373';
    }
  }, [reservation?.status]);

  const handleClickFinishReservation = async () => {
    try {
      await finishReservation(id.toString());
      snackbar('Rezervasyon başarıyla bitirildi', 'success');
      fetchReservation();
    } catch (error) {
      console.log(error);
    }
  };

  const handleClickCancelReservation = async () => {
    try {
      showModal(
        <CancelReservationModal reservationId={id.toString()} onSuccessCallback={handleOnSuccessCallback} />,
        'Rezervasyonu İptal Et'
      );
    } catch (error) {
      console.log(error);
    }
  };

  const handleClickRemoveFromReservation = async (studentId: number) => {
    try {
      const removeUserFromReservationResponse = await removeUserFromReservation(id, studentId.toString());

      if (removeUserFromReservationResponse?.status) {
        throw new Error(removeUserFromReservationResponse?.data);
      }

      snackbar('Öğrenci rezervasyondan başarıyla çıkartıldı', 'success');
      fetchReservation();
    } catch (error) {
      console.log(error);
    }
  };

  const handleEdit = async (id: number) => {
    try {
      console.log(id);
      const studentResponse: GetStudentResponse = await getStudent(id.toString());
      const classesResponse: ClassesResponse = await getClasses(PAGINATION_PARAMS_FOR_DROPDOWN);

      const mappedClasses = classesResponse.classes.map((item) => ({
        id: item.id,
        label: `${item.number}/${item.branchName}`,
        value: item.id.toString()
      }));

      showModal(
        <CreateOrEditStudentModal
          student={studentResponse}
          classesOptions={mappedClasses}
          onSuccessCallback={handleOnSuccessCallback}
        />,
        'Öğrenci Düzenle'
      );
    } catch (error) {
      console.log(error);
    }
  };

  const handleDelete = async (id: number) => {
    try {
      await deleteStudent(id.toString());
      snackbar('Başarıyla silindi', 'success');
      await handleOnSuccessCallback();
    } catch (error) {
      snackbar('Bir şeyler hatalı gitti', 'error');
    }
  };

  const toDataURL = async (url: string): Promise<string | ArrayBuffer | null> => {
    try {
      const response = await fetch(url);
      const blob = await response.blob();

      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });
    } catch (error) {
      console.error('Görsel base64 formatına dönüştürülemedi:', error);
      return null;
    }
  };

  const handleClickExportPdf = async () => {
    const date = convertUTCToDisplayPdfJustDay(reservation.reservationStartDate);
    const fileName = `${reservation.lessonName}-${date}.pdf`;
    const image = await toDataURL('https://app.edumiu.com/drg.png');
    // const image = await toDataURL('http://localhost:3000/drg.png');

    const data = {
      image,
      participants: reservation.madeReservations,
      teacherName: reservation.teacherNameSurname,
      salon: reservation.salonName,
      lessonName: reservation.lessonName,
      date
    };

    const blob = await pdf(<GenerateParticipantsFile data={data} />).toBlob();
    saveAs(blob, fileName);
  };

  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
      <Card p='20px' borderRadius='20px'>
        <Flex justifyContent={'space-between'}>
          <Box>
            <Text fontSize='sm'>Ders</Text>
            <Text fontSize='lg' fontWeight='bold'>
              {reservation?.lessonName}
            </Text>
          </Box>

          <Flex flexDirection={{ sm: 'column', md: 'row' }} rowGap={{ sm: 3, md: 'unset' }} columnGap={10}>
            <Box>
              <Text fontSize='sm'>Öğretmen</Text>
              <Text fontSize='lg' fontWeight='bold'>
                {reservation?.teacherNameSurname}
              </Text>
            </Box>
            <Box>
              <Text fontSize='sm'>Tarih</Text>
              <Text fontSize='lg' fontWeight='bold'>
                {convertUTCToDisplayPdf(reservation?.reservationStartDate, reservation?.reservationEndDate)}
              </Text>
            </Box>
            <Box>
              <Text fontSize='sm'>Katılımcı</Text>
              <Text fontSize='lg' fontWeight='bold'>
                {reservation?.madeReservationCount}/{reservation?.capacity}
              </Text>
            </Box>
            <Box>
              <Text fontSize='sm'>Aktiflik</Text>
              <Flex columnGap={2} alignItems='center'>
                <Text fontSize='lg' fontWeight='extrabold' color={getActiveStatusColors}>
                  {getActiveStatus}
                </Text>
                <Flex w='18px' h='18px' padding='4px' borderRadius='100px' bg={getActiveStatusColors}></Flex>
              </Flex>
            </Box>
          </Flex>
        </Flex>
      </Card>
      <SimpleGrid spacing={2} position='relative' mt={10}>
        <Flex w='100%' justifyContent={Number(reservation?.madeReservationCount) > 0 ? 'space-between' : 'end'}>
          {Number(reservation?.madeReservationCount) > 0 && (
            <ControlledButton
              variant='outline'
              colorScheme='whatsapp'
              roles={[USER_ROLES.TEACHER, USER_ROLES.ADMIN, USER_ROLES.SUPER_ADMIN]}
              onClick={handleClickExportPdf}
            >
              PDF'e Aktar
            </ControlledButton>
          )}
          <Flex mb='10px' columnGap={5}>
            {reservation?.status === 'Active' && (
              <ControlledButton
                variant='outline'
                colorScheme='red'
                roles={[USER_ROLES.TEACHER, USER_ROLES.ADMIN, USER_ROLES.SUPER_ADMIN]}
                onClick={handleClickFinishReservation}
              >
                Rezervasyonu Bitir
              </ControlledButton>
            )}
            {reservation?.status === 'Active' && (
              <ControlledButton
                variant='solid'
                roles={[USER_ROLES.TEACHER, USER_ROLES.ADMIN, USER_ROLES.SUPER_ADMIN]}
                onClick={() => handleClickCancelReservation()}
              >
                Rezervasyonu İptal Et
              </ControlledButton>
            )}
            {reservation?.status !== 'Canceled' &&
              Array.isArray(reservation?.madeReservations) &&
              reservation?.madeReservations.length > 0 && (
                <ControlledButton
                  colorScheme='orange'
                  roles={[USER_ROLES.TEACHER, USER_ROLES.ADMIN, USER_ROLES.SUPER_ADMIN]}
                  onClick={handleClickSaveAttendance}
                >
                  Yoklamayı Kaydet
                </ControlledButton>
              )}
          </Flex>
        </Flex>
        <DataTable
          tableData={reservation?.madeReservations || []}
          columns={columnsDataCheck}
          loading={loading}
          error={error}
          handleOnChangeParticipants={handleOnChangeParticipants}
          participants={participants}
          displayAttendanceOptions={reservation?.status !== 'Canceled'}
          displayRemoveFromReservation={reservation?.status !== 'Canceled'}
          handleClickRemoveFromReservation={handleClickRemoveFromReservation}
          handleEditClick={handleEdit}
          handleDeleteClick={handleDelete}
        />
      </SimpleGrid>
    </Box>
  );
}
