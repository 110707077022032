import { Box, Flex, SimpleGrid, Text, useColorModeValue } from '@chakra-ui/react';

import StudentBanner from 'views/admin/profile/components/student-banner';

import Card from 'components/card/Card';
import PieChart from 'components/charts/PieChart';
import { HSeparator } from 'components/separator/Separator';
import { Fragment, useEffect, useMemo, useState } from 'react';
import { profile } from 'api/services/auth';
import { ProfileAnalysis, ProfileAnalysisResponse } from 'api/services/auth/types';
import { useAppSelector } from 'lib/hooks';
import { getLessonColor } from 'helpers/color';
import { useNavigate, useParams } from 'react-router-dom';
import { USER_ROLES } from 'constants/users';

export default function Profile() {
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const { id } = useParams();
  const navigate = useNavigate();
  const session = useAppSelector((selector) => selector.session);

  useEffect(() => {
    if (id && session.role === USER_ROLES.STUDENT) {
      navigate('/panel/home');
    }
  }, [id, navigate, session]);

  const [profileData, setProfileData] = useState<ProfileAnalysisResponse>({
    lessonStatistics: [],
    className: '',
    myActiveReservationsCount: '',
    myJoinReservationsCount: '',
    nameSurname: '',
    username: '',
    totalQuestionsAsked: 0
  });

  const [selectedLesson, setSelectedLesson] = useState<ProfileAnalysis | null>(null);
  const [percentages, setPercentages] = useState<number[]>([]);

  const fetchProfileData = async () => {
    const profileResponse: ProfileAnalysisResponse = await profile(id);

    if (!Array.isArray(profileResponse?.lessonStatistics)) {
      return;
    }

    const percentagesData = profileResponse.lessonStatistics.map((profile) => {
      return parseInt(profile.percentage.replace('%', ''));
    });

    setPercentages(percentagesData);

    setProfileData(profileResponse);
  };

  useEffect(() => {
    fetchProfileData();
  }, []);

  const colors = useMemo(() => {
    if (Array.isArray(profileData.lessonStatistics) && profileData.lessonStatistics.length > 0) {
      return getLessonColor(profileData.lessonStatistics);
    }
  }, [profileData.lessonStatistics]);

  const pieChartOptions = (data: any[]) => {
    const labels = data.map((item) => item.lessonName);

    return {
      chartOptions: {
        labels,
        colors,
        chart: {
          width: '50px'
        },
        states: {
          hover: {
            filter: {
              type: 'none'
            }
          }
        },
        legend: {
          show: false
        },
        dataLabels: {
          enabled: false
        },
        hover: { mode: 'nearest' },
        plotOptions: {
          donut: {
            expandOnClick: false,
            donut: {
              labels: {
                show: false
              }
            }
          }
        },
        fill: {
          colors
        },
        tooltip: {
          enabled: true,
          theme: 'dark'
        }
      },
      lessonColors: data.map((item, index) => ({
        lessonName: item.lessonName,
        color: colors[index]
      }))
    };
  };

  const { chartOptions, lessonColors } = pieChartOptions(profileData.lessonStatistics);

  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
      {/* Main Fields */}
      <StudentBanner
        avatar={session.organization.logoUrl}
        name={profileData.nameSurname}
        className={profileData.className}
        userName={id ? profileData.username : ''}
        myActiveReservationsCount={profileData.myActiveReservationsCount}
        myJoinReservationsCount={profileData.myJoinReservationsCount}
        totalQuestionsAsked={profileData.totalQuestionsAsked}
      />

      <SimpleGrid gap={10} mt={{ sm: 10, md: 0 }} columns={selectedLesson ? { sm: 1, md: 2 } : 1}>
        <Card p='20px' alignItems='center' flexDirection='column' w='100%'>
          <Flex
            px={{ base: '0px', '2xl': '10px' }}
            justifyContent='space-between'
            alignItems='center'
            w='100%'
            mb='8px'
          >
            <Text color={textColor} fontSize='md' fontWeight='600' mt='4px'>
              Ders Analizi
            </Text>
          </Flex>

          {Array.isArray(profileData.lessonStatistics) && profileData.lessonStatistics.length > 0 && (
            <PieChart h='100%' w='100%' chartData={percentages} chartOptions={chartOptions} />
          )}
          <Flex w={selectedLesson ? '50%' : { sm: '50%', md: '25%' }} flexDirection='column' gap={2}>
            {profileData.lessonStatistics.map((data, index) => (
              <Fragment key={index}>
                <Flex
                  w='100%'
                  justifyContent='space-between'
                  cursor='pointer'
                  onClick={() => setSelectedLesson(data)}
                  p={1}
                >
                  <Flex align='center'>
                    <Box
                      h='8px'
                      w='8px'
                      bg={lessonColors.find((colorItem) => colorItem.lessonName === data.lessonName)?.color}
                      borderRadius='50%'
                      me='4px'
                    />
                    <Text fontSize='md' color='secondaryGray.600' fontWeight='700' mb='5px'>
                      {data.lessonName}
                    </Text>
                  </Flex>
                  <Text fontSize='lg' color={textColor} fontWeight='700'>
                    {data.percentage}
                  </Text>
                </Flex>
                {index !== profileData.lessonStatistics.length - 1 && <HSeparator />}
              </Fragment>
            ))}
          </Flex>
        </Card>

        {selectedLesson && (
          <Card p='20px' alignItems='center' flexDirection='column' w='100%'>
            <Flex px={{ base: '0px', '2xl': '10px' }} justifyContent='center' alignItems='center' w='100%' mb='8px'>
              <Text color={textColor} fontSize='md' fontWeight='600' mt='4px'>
                {selectedLesson.lessonName} Kazanımları
              </Text>
            </Flex>

            {selectedLesson.lessonSubjects.map((lessonSubject, key) => (
              <Flex key={key} flexDirection='row' w='50%' justifyContent='space-between' mt={5} alignItems='center'>
                <Text color={textColor} fontSize='md' mt='4px'>
                  {lessonSubject.name}:
                </Text>
                <Text color={textColor} fontSize='md' fontWeight='600' mt='4px'>
                  {lessonSubject.count}
                </Text>
              </Flex>
            ))}
          </Card>
        )}
      </SimpleGrid>
    </Box>
  );
}
