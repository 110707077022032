import { AddIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Checkbox,
  Flex,
  Menu,
  MenuButton,
  MenuList,
  RadioGroup,
  SimpleGrid,
  Text,
  useColorModeValue
} from '@chakra-ui/react';
import { deleteStudent, getStudent, unbanStudent } from 'api/services/students';

import DataTable from 'components/shared/data-table';
import { Column } from 'components/shared/data-table/types';
import ControlledButton from 'components/with-controlled/button';
import { USER_ROLES } from 'constants/users';
import { useUI } from 'contexts/UIContext';
import { useEffect, useState } from 'react';
import { StudentsRowObj } from './types';
import { useCustomSnackbar } from 'utils/custom-snackbar';
import { getStudents } from 'api/services/students';
import CreateOrEditStudentModal from 'components/students/create-or-edit-student-modal';
import { getClasses } from 'api/services/classes';
import { ClassesResponse } from 'api/services/classes/types';
import { GetStudentResponse, StudentsResponse } from 'api/services/students/types';
import { PAGINATION_PARAMS_FOR_DROPDOWN } from 'constants/fetch';
import ControlledMenuItem from 'components/with-controlled/menu-item';
import { IoFilter } from 'react-icons/io5';

const columnsDataCheck: Column<StudentsRowObj>[] = [
  { name: 'nameSurname', displayName: 'Adı Soyadı' },
  { name: 'userName', displayName: 'Kullanıcı Adı' },
  { name: 'email', displayName: 'E-Mail Adresi' },
  { name: 'className', displayName: 'Sınıfı' }
];

export default function Students() {
  const { showModal } = useUI();
  const [loading, setLoading] = useState(true);
  const [students, setStudents] = useState<StudentsResponse>({ totalCount: 0, students: [] });
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [isBan, setIsBan] = useState(false);

  const snackbar = useCustomSnackbar();
  const shadow = useColorModeValue(
    '14px 17px 40px 4px rgba(112, 144, 176, 0.18)',
    '14px 17px 40px 4px rgba(112, 144, 176, 0.06)'
  );
  let menuBg = useColorModeValue('white', 'navy.800');

  const fetchStudents = async (page?: number, pageSize?: number, isBan?: boolean) => {
    try {
      const studentsResponse = await getStudents({
        page: page?.toString(),
        pageSize: pageSize?.toString(),
        isBan: isBan
      });

      setStudents(studentsResponse);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleOnSuccessCallback = async () => {
    await fetchStudents(page, pageSize, isBan);
  };

  useEffect(() => {
    fetchStudents(page, pageSize, isBan);
  }, []);

  const handleClickCreateStudent = async () => {
    const classesResponse: ClassesResponse = await getClasses(PAGINATION_PARAMS_FOR_DROPDOWN);

    const mappedClasses = classesResponse.classes.map((item) => ({
      id: item.id,
      label: `${item.number}/${item.branchName}`,
      value: item.id.toString()
    }));

    showModal(
      <CreateOrEditStudentModal classesOptions={mappedClasses} onSuccessCallback={handleOnSuccessCallback} />,
      'Öğrenci Oluştur'
    );
  };

  const handleEdit = async (id: number) => {
    try {
      const studentResponse: GetStudentResponse = await getStudent(id.toString());
      const classesResponse: ClassesResponse = await getClasses(PAGINATION_PARAMS_FOR_DROPDOWN);

      const mappedClasses = classesResponse.classes.map((item) => ({
        id: item.id,
        label: `${item.number}/${item.branchName}`,
        value: item.id.toString()
      }));

      showModal(
        <CreateOrEditStudentModal
          student={studentResponse}
          classesOptions={mappedClasses}
          onSuccessCallback={handleOnSuccessCallback}
        />,
        'Öğrenci Düzenle'
      );
    } catch (error) {
      console.log(error);
    }
  };

  const handleDelete = async (id: number) => {
    try {
      await deleteStudent(id.toString());
      snackbar('Başarıyla silindi', 'success');
      await fetchStudents(page, pageSize, isBan);
    } catch (error) {
      snackbar('Bir şeyler hatalı gitti', 'error');
    }
  };

  const onPaginationChange = async (pageIndex: number, pageSize: number) => {
    setPage(pageIndex + 1);
    setPageSize(pageSize);
    await fetchStudents(pageIndex + 1, pageSize, isBan);
  };

  const handleChangeIsBanStudent = async (checked: boolean) => {
    setIsBan(checked);

    await fetchStudents(page, pageSize, checked);
  };

  const handleUnban = async (id: number) => {
    const unbanStudentResponse = await unbanStudent(id.toString());

    if (unbanStudentResponse?.data && unbanStudentResponse?.status === 400) {
      snackbar(unbanStudentResponse.data, 'error');
      return;
    }

    snackbar(unbanStudentResponse, 'success');
  };

  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
      <SimpleGrid spacing={2}>
        <Flex justifyContent='end' w='100%' mb='10px'>
          <ControlledButton
            variant='outline'
            colorScheme='telegram'
            rightIcon={<AddIcon />}
            roles={[USER_ROLES.ADMIN, USER_ROLES.SUPER_ADMIN]}
            onClick={handleClickCreateStudent}
          >
            Yeni Ekle
          </ControlledButton>
          <Menu>
            <MenuButton p='0px'>
              <Button colorScheme='telegram' rightIcon={<IoFilter />} ml={5}>
                Filtreler
              </Button>
            </MenuButton>
            <MenuList boxShadow={shadow} mt='10px' borderRadius='20px' bg={menuBg} border='none'>
              <Flex flexDirection='column' p='10px'>
                <ControlledMenuItem
                  _hover={{ bg: 'none' }}
                  _focus={{ bg: 'none' }}
                  borderRadius='8px'
                  px='14px'
                  closeOnSelect={false}
                  roles={[USER_ROLES.ADMIN, USER_ROLES.SUPER_ADMIN]}
                >
                  <Checkbox checked={isBan} onChange={(e) => handleChangeIsBanStudent(e.target?.checked)}>
                    Kısıtlaması olan öğrencileri göster
                  </Checkbox>
                </ControlledMenuItem>
              </Flex>
            </MenuList>
          </Menu>
        </Flex>
        <DataTable
          tableData={students.students}
          columns={columnsDataCheck}
          handleUnbanClick={handleUnban}
          handleEditClick={handleEdit}
          handleDeleteClick={handleDelete}
          loading={loading}
          totalCount={students.totalCount}
          onPaginationChangeCallback={onPaginationChange}
          displayProfileButton
        />
      </SimpleGrid>
    </Box>
  );
}
