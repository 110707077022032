import { createHttpRequests } from 'api/requests';
import {
  StudentsResponse,
  CreateStudentRequest,
  CreateStudentResponse,
  UpdateStudentRequest,
  UpdateStudentResponse,
  GetStudentResponse,
  UpdateReservationRestrictionRequest,
  UnbanStudentResponse,
  UnbanStudentRequest
} from './types';
import endpoints from 'constants/endpoints';
import axiosServices from 'api';

const { getRequest, postRequest, putRequest, deleteRequest } = createHttpRequests(axiosServices);

export const getStudents = async (data?: {
  page: string;
  pageSize: string;
  isBan: boolean;
}): Promise<StudentsResponse | any> => {
  try {
    const response = await getRequest<StudentsResponse>(endpoints.students, {
      page: data.page,
      pageSize: data.pageSize,
      isBan: data.isBan
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const getStudent = async (id: string): Promise<GetStudentResponse | any> => {
  try {
    const response = await getRequest<any>(endpoints.student(id));
    return response;
  } catch (error) {
    return error;
  }
};

export const createStudent = async (data: CreateStudentRequest): Promise<CreateStudentResponse | any> => {
  try {
    const response = await postRequest<CreateStudentResponse>(endpoints.students, data);
    return response;
  } catch (error) {
    return error;
  }
};

export const unbanStudent = async (id: string): Promise<UnbanStudentResponse | any> => {
  try {
    const response = await postRequest<UnbanStudentResponse>(endpoints.unbanStudent(id));
    return response;
  } catch (error) {
    return error;
  }
};

export const updateStudent = async (data: UpdateStudentRequest, id: string): Promise<UpdateStudentResponse | any> => {
  try {
    const response = await putRequest<UpdateStudentResponse>(endpoints.student(id), data);
    return response;
  } catch (error) {
    return error;
  }
};

export const deleteStudent = async (id: string): Promise<any | any> => {
  try {
    const response = await deleteRequest<any>(endpoints.student(id));
    return response;
  } catch (error) {
    return error;
  }
};

export const reservationRestriction = async (
  data: UpdateReservationRestrictionRequest,
  id: string
): Promise<any | any> => {
  try {
    const response = await putRequest<any>(endpoints.reservationRestriction(id), data);
    return response;
  } catch (error) {
    return error;
  }
};
