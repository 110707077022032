import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  useColorMode,
  Text
} from '@chakra-ui/react';
import { Field, Form, Formik, FormikHelpers, FieldProps } from 'formik';
import { JoinReservationModalProps, JoinReservationModalSubmit } from './types';
import * as Yup from 'yup';
import { useUI } from 'contexts/UIContext';
import { useState } from 'react';
import { useCustomSnackbar } from 'utils/custom-snackbar';
import { joinReservation } from 'api/services/reservations';
import { Select as MultiSelect } from 'chakra-react-select';

export default function JoinReservationModal(props: JoinReservationModalProps) {
  const { hideModal } = useUI();
  const snackbar = useCustomSnackbar();
  const { reservationId, lessonsSubjects, onSuccessCallback } = props;
  const { colorMode } = useColorMode();

  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (
    values: JoinReservationModalSubmit,
    { setSubmitting }: FormikHelpers<JoinReservationModalSubmit>
  ) => {
    try {
      setIsLoading(true);

      const joinReservationResponse = await joinReservation(reservationId.toString(), {
        lessonSubjects: values.lessonSubjects
      });

      if (joinReservationResponse.status === 400) {
        if (Array.isArray(joinReservationResponse.data)) {
          const errorMessages = joinReservationResponse.data.map((error: any) => error.description).join(' ');
          throw new Error(errorMessages);
        }

        throw new Error(joinReservationResponse.data);
      }

      if (joinReservationResponse.id) {
        snackbar('Rezervasyon başarıyla oluşturuldu', 'success');
        return onSuccessCallback();
      }

      throw new Error('Bir şeyler hatalı gitti');
    } catch (err: any) {
      snackbar(err.message, 'error');
    } finally {
      setSubmitting(false);
      setIsLoading(false);
      hideModal();
    }
  };

  return (
    <Formik<JoinReservationModalSubmit>
      initialValues={{
        lessonSubjects: []
      }}
      validationSchema={Yup.object().shape({
        lessonSubjects: Yup.array()
          .of(
            Yup.object().shape({
              lessonSubjectId: Yup.number().required('Kazanım ID zorunludur'),
              questionCount: Yup.number().min(1, 'En az 1 soru olmalı').required('Soru sayısı zorunludur')
            })
          )
          .min(1, 'En az bir kazanım seçmelisiniz')
      })}
      onSubmit={async (values, formikHelpers) => handleSubmit(values, formikHelpers)}
    >
      {({ values, handleChange, setFieldValue, errors }) => (
        <Form>
          <Flex flexDirection='column' gap={3}>
            <Field name='lessonSubjects'>
              {({ field, form }: FieldProps) => (
                <>
                  <FormControl isInvalid={Boolean(form.errors.lessonSubjects && form.touched.lessonSubjects)}>
                    <FormLabel>Kazanımlar</FormLabel>
                    <MultiSelect
                      isMulti
                      name='lessonSubjects'
                      options={lessonsSubjects}
                      placeholder='Kazanımlar'
                      closeMenuOnSelect={false}
                      onChange={(selectedOptions) => {
                        const selectedValues = selectedOptions.map((option) => ({
                          lessonSubjectId: option.value,
                          questionCount: 0
                        }));

                        setFieldValue(field.name, selectedValues);
                      }}
                      chakraStyles={{
                        input: (provided) => ({
                          ...provided,
                          color: colorMode === 'dark' ? 'white' : 'black'
                        })
                      }}
                    />
                    <FormErrorMessage>
                      {typeof errors.lessonSubjects === 'string' ? errors.lessonSubjects : ''}
                    </FormErrorMessage>
                    <FormHelperText>Kazanımları eksiksiz seçtiğinden emin ol.</FormHelperText>
                  </FormControl>
                  {values.lessonSubjects.map((subject, index) => (
                    <Flex key={subject.lessonSubjectId} alignItems='center' gap={5}>
                      <Text fontSize='md' whiteSpace='nowrap' width='50%'>
                        {lessonsSubjects.find((opt) => opt.value === subject.lessonSubjectId.toString())?.label}
                      </Text>
                      <FormControl
                        width='40%'
                        isInvalid={Boolean(form.errors.lessonSubjects && form.touched.lessonSubjects)}
                      >
                        <Input
                          name={`lessonSubjects[${index}].questionCount`}
                          size='md'
                          type='number'
                          placeholder='Soru Sayısı'
                          value={subject.questionCount || ''}
                          onChange={(e) => {
                            const value = parseInt(e.target.value, 10) || 0;
                            setFieldValue(`lessonSubjects[${index}].questionCount`, value);
                          }}
                          _dark={{ color: 'white' }}
                        />
                        <FormErrorMessage>
                          {(errors.lessonSubjects as any)?.[index]?.questionCount || ''}
                        </FormErrorMessage>
                      </FormControl>
                    </Flex>
                  ))}
                </>
              )}
            </Field>
          </Flex>

          <Flex justifyContent={'space-between'} mt={5}>
            <Button variant='ghost' colorScheme='red' onClick={hideModal}>
              İptal
            </Button>
            <Button variant='outline' colorScheme='whatsapp' type='submit' isLoading={isLoading}>
              Onayla
            </Button>
          </Flex>
        </Form>
      )}
    </Formik>
  );
}
